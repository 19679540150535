.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: var(--jasper);
    color: var(--baby-powder);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s, transform 0.3s ease-in-out background-color 1.5s ease-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .back-to-top:hover {
    background-color: var(--jasper-hover);
  }
  
  .back-to-top.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .back-to-top:not(.show) {
    transform: translateY(100%);
  }
  