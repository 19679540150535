nav img {
    height: 100px;
    margin: 15px 0;
    transition: height 0.15s ease-in-out;
}

.navbar {
    background-color: var(--eerie-black);
    color: var(--baby-powder);
    padding: 15px 10vw;
    font-family: "Poppins", sans-serif;
    height: 155px;
    display: flex;
    align-items: center;
    transition: all 0.15s ease-in-out;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
}

.logo.small{
    height: 50px;
    margin: 0;
}

/* Navbar shrinks when scrolling */
.navbar.scrolled {
    height: 75px;
    padding: 10px 10vw;
}

.nav-container {
    display: flex;
    justify-content: start;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}

/* Shrinking logo */
.logo {
    font-size: 22px;
    font-weight: bold;
    color: var(--jasper);
    transition: height 0.15s ease-in-out;
}

.navbar.scrolled nav img {
    height: 75px;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 50px;
    padding-left: 100px;
    transition: all 0.15s ease-in-out;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    text-decoration: none;
    color: var(--baby-powder);
    font-size: 16px;
    transition: color 0.15s ease-in-out;
}

.nav-links a:hover {
    color: var(--cerulean);
}

/* Mobile Menu */
.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--baby-powder);
}

/* Mobile responsive menu */
@media (max-width: 768px) {
    .navbar {
        height: 155px;
        display: flex;
        z-index: 100;
        position: fixed;
        width: 100%;
        box-sizing: border-box;
    }

    .logo.small{
        height: 50px;
        margin: 0;
    }

    .navbar.scrolled {
        height: 75px;
    }

    .nav-container {
        justify-content: space-between;
        width: 100%;
    }

    .menu-toggle {
        display: block;
    }

    .nav-links {
        flex-direction: column;
        position: absolute;
        top: 125px;
        right: 0;
        background-color: var(--eerie-black);
        padding: 35px 0;
        border-top: 1px solid var(--jasper);
        transform: translateX(-100%);
        transition: 0.15s ease-out;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        gap: 35px;
    }

    .scrolledMenu {
        top: 75px !important;
        margin: 0;
    }

    .nav-links.open {
        transform: translateX(0%);
    }

    .nav-links li {
        margin: 0;
    }
}
