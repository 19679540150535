html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

:root{
--jasper: #d7493cff;
--jasper-hover: rgb(182, 65, 54);
--khaki: #a69e94ff;
--baby-powder: #f9fcf5ff;
--eerie-black: #101918ff;
--cerulean: #1c6e8cff;
--blue: #36558F;
--af-blue:#145C9E;
--green:#72B01D;
--white-background:#ffffff;
--white-text:#ffffff;
--light-background: #f9f9fb;
--light-background-hover: #e9e8ee;
--light-border:#e6e1e1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
