body {
    background-color: var(--white-background);
    color: var(--eerie-black);
    font-family: "Poppins", sans-serif;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    padding: 100px 20px;
    margin-top: 125px;
  }
  
  header {
    text-align: center;
    position: relative;
  }

  header .headerContent{
    z-index: 2;
    position: relative;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerContent div{
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #00000078 20%, #00000078 80%, rgba(0, 0, 0, 0) 100%);
    padding: 25px 150px;
    width: fit-content;
  }

  header .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  header .bg .imgWrapper{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
  overflow: hidden;
  align-items: end;
  }

  header .bg img{
    position: absolute;
    margin: 0;
    width: 100%;
    height: auto;
     bottom: -15vh;
  }

  header .bg .imgWrapper div{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.304);
  }

  header img{
    height: 200px;
    margin-top: 25px;
  }

 
  header h1 {
    font-family: "Roboto Slab", serif;
    color:  var(--baby-powder);
    margin: 0;
  }

  
  header h2 {
    color: var(--jasper);
  }
  
  .intro {
    margin:100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro .product-section-title{
    margin-bottom: 50px;
    width: 100%!important;
  }

  .intro .splitter{
    display: flex;
  }

  .intro .splitter div:first-of-type{
    width: 70%;
  }

  .intro .splitter div:last-of-type{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .intro .splitter img{
    height: 200px;
  }

  .intro .why{
    background:var(--jasper);
    padding: 50px;
    border-radius: 8px;
    margin:100px 0 0;
    color: var(--baby-powder);
    width: 100%;
  }

  .intro .why ul{
    list-style: none;
    padding: 0;
  }

  .intro .why h3{
    font-size: 1.3rem;
    margin-bottom: 50px;
  }

  .intro .why ul li{
    margin:15px 0;
    font-size: 1rem;
  }

  .intro p{
    max-width: 1000px;
  }

  .intro p:last-of-type{
    margin-top: 50px;
  }
  
  
  .product-section {
    max-width: 1500px;
    margin: 40px auto;
    padding: 0 20px;
    text-align: center;
  }

  .product-section-title{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .product-section-title i{
    color: var(--jasper);
    margin-right: 15px;
    font-size: 2rem;
  }
  
  .section-title {
    font-size: 2rem;
    margin:0;
    color: var(--eerie-black);
  }

  .catHeader{
    margin: 100px 0 50px;
    display: flex;
    width: 100%;    
    border-bottom: 2px solid var(--jasper);
    justify-content: center;
    align-items: center;
  }

  .catHeader i{
    margin-right: 10px;
    color:var(--jasper);
  }

  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: start;
  }
  
  .product-card {
    background: var(--light-background);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.15s ease-in-out;
    width: 280px; 
    height: 100%;
    box-sizing: border-box;
  }
  
  .product-card:hover {
    transform: scale(1.02);
    background-color: var(--light-background-hover);
  }
  
  .image-container {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--white-background);
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    border:1px solid var(--light-border);
  }
  
  .product-image {
    width: auto;
    height: 100%;
    object-fit: cover; /* Ensures uniform size without stretching */
  }
  
  .product-info {
    padding: 10px 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-info div{
    display: flex;
    justify-content: center;
  }
  
  .product-info h4 {
    font-size: 1.2rem;
    margin: 10px 0;
    color: var(--eerie-black);
  }
  
  .price {
    font-size: 1rem;
    color: var(--green);
    font-weight: bold;
    margin-right: 5px;
  }

  .pricePeriod {
    font-size: 1rem;
    color: var(--eerie-black);
    font-weight: bold;
  }

  .contact-section {
    text-align: center;
    margin: 200px 0 100px;
    padding: 100px 40px;
    background-color: var(--light-background);
    border-radius: 15px;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .contact-section .product-section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .contact-section form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 500px;
  }
  .form-group label{
    text-align: start;
    margin-left: 10px;
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    margin: auto;
    border: 1px solid var(--khaki);
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .contact-button {
    background-color: var(--cerulean);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-button:hover {
    background-color: var(--jasper);
  }
  


  @media (max-width: 768px) {

    .container {
        padding: 25px 2.5vw;
        width: 100%;
        box-sizing: border-box;
        margin-top: 155px;
    }

    header .bg img {
        width: auto;
        height: 100%;
        bottom:0;
    }

    header .headerContent {
        overflow: hidden;
    }

    .intro .splitter{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .intro .splitter div:first-of-type{
        width: 90%;
      }
    
      .intro .splitter div:last-of-type{
        width: 100%;
      }

      .intro .splitter img {
        margin-top: 50px;
        height: 150px;
      }

      .intro .why {
        box-sizing: border-box;
      }

      .catHeader {
        margin: 50px 0;
      }

      .product-grid {
        gap: 25px;
      }

      .product-card {
        margin:auto;
      }

      .contact-section {
        margin: 100px 0;
      }

      .contact-section form {
        width: 100%;
      }

      form{
        margin-top: 25px;
      }

      .form-group {
        width: 100%;
      }


  }