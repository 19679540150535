.footer {
    background-color: var(--eerie-black);
    color: var(--baby-powder);
    text-align: center;
    padding: 50px 0 20px;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .footer-logo {
    width: 150px;
    height: auto;
  }
  
  .footer-links {
    display: flex;
    gap: 15px;
  }
  
  .footer-links a {
    color: var(--baby-powder);
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-links a:hover {
    color: var(--cerulean);
  }
  
  .footer-text {
    font-size: 14px;
    margin-top: 10px;
  }
  